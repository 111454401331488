import logo from './luca-smol.png';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <img src={logo} className="App-logo" alt="logo" />

        <p>
         timCrider.com
        </p>

        <hr style={{width: '90%', padding: 0}}/>
        <p className="LinkBar">
          <a href="https://github.com/timcrider" className="App-link">GitHub</a>
          &nbsp;|&nbsp;
          <a href="https://bsky.app/profile/timcrider.bsky.social" className="App-link">Blue Sky</a>
          &nbsp;|&nbsp;
          <a href="https://npmjs.com/~tcrider/" className="App-link">npmjs</a>
        </p>

      </header>
    </div>
  );
}

export default App;
